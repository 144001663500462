<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 >
              <bets-filter :eventIdParam="eventIdParam" :fromTimeParam = "fromTimeParam" :toTimeParam="toTimeParam" :signEventParam="signEventParam" :marketNameParam="marketNameParam" :outcomeNameParam="outcomeNameParam"></bets-filter>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
              <bets-list></bets-list>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import moment from 'moment';
import BetsList from "@/components/bets/betsList";
import BetsFilter from "@/components/bets/betsFilter";

export default {
  name: "Bets",
  components: {BetsFilter, BetsList},
  data() {
    return {
      eventIdParam: null,
      fromTimeParam : null,
      toTimeParam: null,
      signEventParam: null,
      marketNameParam: null,
      outcomeNameParam: null
    }
  },
  computed: {
    ...mapGetters({
      'getLanguage': 'profile/getLanguage',
      'getToken': "profile/getToken",
      'getPlatformId': "rules/getPlatformId",
      'getOptions': 'bet/getOptions',
    }),

  },

  async mounted() {
    // await this.setPreload(true);
    // await this.fetchBets({page: this.getOptions.page, limit: this.getOptions.limit, platform_id: this.getPlatformId})
    // await this.setPreload(false);

    const eventId = this.$route.query.eventId;
    const fromTime = this.$route.query.fromTime;
    const toTime = this.$route.query.toTime;
    const signEvent = this.$route.query.signEvent;
    const marketName = this.$route.query.marketName;
    const outcomeName = this.$route.query.outcomeName;

    if (eventId) {
      this.eventIdParam = eventId;
      this.fromTimeParam = fromTime;
      this.toTimeParam = toTime;
      this.signEventParam = signEvent;
      this.marketNameParam = marketName;
      this.outcomeNameParam = outcomeName;
    }
  },
  methods: {
    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchBets': 'bet/fetchBets',
      'setOptions': 'bet/setOptions'
    }),
    ...mapMutations({
      'setLanguage': 'profile/setLanguage',
    }),

  },
}
</script>

<style scoped>

</style>
