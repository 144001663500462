<template>
  <div id="cart_settings">
    <v-container fluid>
      <v-layout row>

        <v-col cols="12" v-if="this.default == true">
          *{{$t('cart.defaultValuesSaveCartSettings')}}
        </v-col>
        <v-flex md12 v-if="this.getPermissions[0].name == 'Administrator'">
          <v-col cols="12">
            <span class="title-table">{{ this.$t('generic.context') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="3">
                    <label class="text-uppercase font-weight-bold">{{ $t('generic.context') }} {{ $t('bets.type') }}</label>
                    <div class="d-flex">
                      <v-checkbox v-model="enablePrematch" :label="$t('bets.prematch')" dense hide-details readonly disabled></v-checkbox>
                      <v-checkbox v-model="enableLive" :label="$t('bets.live')" dense hide-details class="pl-5"></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
        <v-flex md6>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.cart') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="2">
                    <label class="text-uppercase font-weight-bold">{{$t('bets.cart')}} {{$t('bets.type')}}</label>
                    <v-radio-group v-model="cartType">
                      <v-radio :label="$t('generic.classic')" value="classic"></v-radio>
                      <v-radio :label="$t('generic.exploded')" value="exploded"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label class="text-uppercase font-weight-bold">{{ $t('bets.bet') }} {{ $t('bets.type') }}</label>
                    <div class="d-flex">
                      <v-checkbox v-model="betSingle" :label="$t('bets.single')" dense hide-details></v-checkbox>
                      <v-checkbox v-model="betMultiple" :label="$t('bets.multiple')" dense hide-details class="pl-5"></v-checkbox>
                    </div>
                    <div class="d-flex">
                      <v-checkbox v-model="betIntegral" :label="$t('bets.integral')" dense hide-details></v-checkbox>
                      <v-checkbox v-model="betSystem" :label="$t('bets.system')" dense hide-details class="pl-2"></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <label class="text-uppercase font-weight-bold">{{ $t('bets.integral') }} {{$t('bets.type')}}</label>
                    <v-radio-group v-model="betIntegralDivided">
                      <v-radio :label="$t('bets.amount')+' '+$t('generic.divided')+' ('+minAmount+currencySymbol+' / N° '+$t('bets.combinations')+')'" v-bind:value="true"></v-radio>
                      <v-radio :label="$t('bets.amount')+' '+$t('generic.multiplied')+' ('+minAmount+currencySymbol+' * N° '+$t('bets.combinations')+')'" v-bind:value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
        <v-flex md2>
          <v-col cols="12">
            <span class="title-table">&nbsp;</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                        v-model="currency"
                        :label="$t('generic.currency')+' (Euro, Dollaro)'"
                        dense
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="currencySymbol"
                        :label="$t('generic.currency')+' '+$t('generic.symbol')+'(€,$)'"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>
        <v-flex md4>
          <v-col cols="12">
            <span class="title-table">&nbsp;</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <label class="font-weight-bold text-uppercase">{{$t('min')}} {{$t('cart')}} {{$t('amount')}} {{ currencySymbol }}</label>
<!--                    <v-slider-->
<!--                        class="mt-10"-->
<!--                        v-model="minAmount"-->
<!--                        append-icon="mdi-plus-circle"-->
<!--                        prepend-icon="mdi-minus-circle"-->
<!--                        @click:append="changeAmountMin(0.5)"-->
<!--                        @click:prepend="changeAmountMin(-0.5)"-->
<!--                        step="0.5"-->
<!--                        max="500000"-->
<!--                    ></v-slider>-->
                    <v-slider
                        v-model="minAmount"
                        class="mt-10"
                        max="500000"
                        min="0"
                        hide-details
                        thumb-label="always"
                        thumb-size="45"
                        step="0.01"
                    >
                      <template v-slot:append>
                        <v-text-field
                            v-model="minAmount"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 100px"
                        ></v-text-field>
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ $t('bets.cart') | capitalize}} {{ $t('buttons') }}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-checkbox v-model="enableButtonsCart" :label="$t('enableButtonsCart')" dense hide-details></v-checkbox>
                    </div>
                  </v-col>
                  <v-col md="2" v-for="(btn,index) in buttonsCart" :key="index" class="pt-5">
                    <v-text-field
                        v-model="btn.value"
                        :label="$t('amount')+' '+currencySymbol"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.prematch') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col md="2">
                    <v-text-field
                        v-model="max_winning"
                        :label="$t('maxWinningCart')+' '+currencySymbol"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="max_odds"
                        :label="$t('maxOddsCart')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col md="2">
                    <v-text-field
                            v-model="prematch_max_amount"
                            :label="$t('generic.max')+' '+$t('bets.amount') +' '+currencySymbol"
                            dense
                            required>
                    </v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('bets.live') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col md="2">
                    <v-text-field
                        v-model="sleep_live"
                        :label="$t('waitTimeLive')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="live_min_amount"
                        :label="$t('generic.min')+' '+$t('bets.amount') +' '+currencySymbol"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="live_max_amount"
                        :label="$t('generic.max')+' '+$t('bets.amount') +' '+currencySymbol"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="live_max_winning"
                        :label="$t('generic.max')+' '+$t('bets.winning') +' '+currencySymbol"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="live_max_multiplicity"
                        :label="$t('generic.max')+' '+$t('bets.multiplicity')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('repetition') | capitalize}} {{ this.$t('vincol') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col md="2">
                    <v-text-field
                        v-model="repetitionVincol.prematch"
                        :label="$t('repetition')+' '+$t('prematch')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="repetitionVincol.live"
                        :label="$t('repetition')+' '+$t('live.live')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                        v-model="repetitionVincol.mixed"
                        :label="$t('repetition')+' '+$t('mixed')"
                        dense
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('generic.settings') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-checkbox v-model="enableQuickBetOnlyForAgency" :label="$t('enableQuickbetOnlyForAgency')" color="secondary" hide-details></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-checkbox v-model="enableDirectPrintAgency" :label="$t('enableDirectPrint')" color="secondary" hide-details></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-checkbox v-model="enableAutoOpenFavourite" :label="$t('enableAutoOpenFavourite')" color="secondary" hide-details></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-checkbox v-model="enableShortCode" :label="$t('enableShortCode')" color="secondary" hide-details></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md4>
          <v-col cols="12">
            <span class="title-table">{{ this.$t('info') | capitalize}}</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <span class="text-uppercase font-weight-bold">{{ $t('bets.single') }} / {{ $t('bets.multiple') }}</span>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0">
                    <v-checkbox v-model="sm_multiplicity" :label="$t('bets.multiplicity')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sm_bonus" :label="$t('bets.bonus')+' %'" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0">
                    <v-checkbox v-model="sm_odds_total" :label="$t('bets.odds')+' '+$t('generic.total')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sm_winning" :label="$t('bets.winning')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0">
                    <v-checkbox v-model="sm_bonus_total" :label="$t('bets.bonus')+' '+$t('generic.total')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sm_profit" :label="$t('bets.profit')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0">
                    <v-checkbox v-model="sm_winning_total" :label="$t('bets.winning')+' '+$t('generic.total')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md8>
          <v-col cols="12">
            <span class="title-table">&nbsp;</span>
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <span class="text-uppercase font-weight-bold">{{ $t('bets.integral') }}</span>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_multiplicity" :label="$t('bets.multiplicity')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_combinations" :label="$t('bets.combinations')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_odds_total_min" :label="$t('bets.odds')+' '+$t('generic.total')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_odds_total_max" :label="$t('bets.odds')+' '+$t('generic.total')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_winning_min" :label="$t('bets.winning')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_winning_max" :label="$t('bets.winning')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_bonus_min" :label="$t('bets.bonus')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_bonus_max" :label="$t('bets.bonus')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_profit_min" :label="$t('bets.profit')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_profit_max" :label="$t('bets.profit')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="in_winning_total_min" :label="$t('bets.winning')+' '+$t('generic.total')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="in_winning_total_max" :label="$t('bets.winning')+' '+$t('generic.total')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <span class="text-uppercase font-weight-bold">{{ $t('bets.system') }}</span>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="sys_combinations" :label="$t('bets.combinations')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sys_amount_total" :label="$t('bets.amount')+' '+$t('generic.total')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="sys_bonus_min" :label="$t('bets.bonus')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sys_bonus_max" :label="$t('bets.bonus')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="sys_winning_min" :label="$t('bets.winning')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sys_winning_max" :label="$t('bets.winning')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="sys_winning_total_min" :label="$t('bets.winning')+' '+$t('generic.total')+' '+$t('generic.min')" color="secondary" hide-details></v-checkbox>
                    <v-checkbox v-model="sys_winning_total_max" :label="$t('bets.winning')+' '+$t('generic.total')+' '+$t('generic.max')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2" class="pt-0">
                    <v-checkbox v-model="sys_winning_all_total" :label="$t('bets.winning')+' '+$t('generic.all')+' '+$t('generic.total')" color="secondary" hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-flex>

        <v-flex md12>
          <v-col cols="6" offset-md="3">
            <v-btn block depressed color="secondary" class="mr-2" @click="submitRule">
              {{ $t('generic.save') }}
            </v-btn>
          </v-col>
        </v-flex>

      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.snackbar = false">
        {{ $t('generic.close') }}
      </v-btn>
    </v-snackbar>
  </div>
</template>
<style>

</style>
<script>
import axios from 'axios'
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash'
import menuBetConsole from "@/components/betConsole/menu";

export default {
  name: "Cart",
  components: {menuBetConsole},
  data() {
    return {
      default: true,
      typeRule: 'cart',
      source: 'betconsole',
      scope: 'cart',
      cartType: 'classic',
      betSingle: true,
      betMultiple: true,
      betIntegral: true,
      betIntegralDivided: false,
      betSystem: true,
      currency: 'EUR',
      currencySymbol: '€',
      minAmount: 10.00,

      enablePrematch: true,
      enableLive: true,

      sm_multiplicity: true,
      sm_bonus: true,
      sm_odds_total: true,
      sm_winning: true,
      sm_bonus_total: true,
      sm_profit: true,
      sm_winning_total: true,

      in_multiplicity: true,
      in_combinations: true,
      in_odds_total_min: true,
      in_odds_total_max: true,
      in_winning_min: true,
      in_winning_max: true,
      in_bonus_min: true,
      in_bonus_max: true,
      in_profit_min: true,
      in_profit_max: true,
      in_winning_total_min: true,
      in_winning_total_max: true,

      sys_combinations: true,
      sys_amount_total: true,
      sys_bonus_min: true,
      sys_winning_min: true,
      sys_winning_total_min: true,
      sys_bonus_max: true,
      sys_winning_max: true,
      sys_winning_total_max: true,
      sys_winning_all_total: true,

      max_winning: 0,
      max_odds: 0,

      live_min_amount: 0,
      live_max_amount: 0,
      live_max_winning: 0,
      live_max_multiplicity: 0,
      sleep_live: 0,

      enableQuickBetOnlyForAgency: false,
      enableDirectPrintAgency: false,
      enableAutoOpenFavourite: false,
      enableShortCode: false,

      enableButtonsCart: false,
      buttonsCart: [{value: 0},{value: 0}, {value: 0}],
      repetitionVincol: {live:3, prematch:3, mixed:3},

      snackbar: {
        snackbar: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
      },
      prematch_max_amount: 0

    }
  },

  computed: {
    ...mapGetters({
      'getToken': 'profile/getToken',
      'getUrl': 'settings/getUrl',
      'getLanguage': 'profile/getLanguage',
      'getPlatformId': 'rules/getPlatformId',
      'getListSkins': 'skins/getList',
      'getPermissions': 'profile/getPermissions',
    }),


  },
  async created() {
    var vue = this
    await vue.setPreload(true);
    await vue.fetchSkins();
    await vue.getRulesCart(vue.getPlatformId)
    await vue.setPreload(false);
  },


  methods: {

    ...mapActions({
      'setPreload': 'utils/setPreload',
      'fetchSkins': 'skins/fetchSkins',
      'updateRules': 'skins/updateRules',
      'cacheRules': 'cache/cacheRules',
    }),

    ...mapMutations({}),


    changeAmountMin(val) {
      this.minAmount = this.minAmount + val
    },

    async submitRule() {

      var vue = this;
      await this.setPreload(true);

      var payload = {
        "typeRule": vue.typeRule,
        "source": vue.source,
        "scope": vue.scope,
        "rule": {
          "enablePrematch": vue.enablePrematch,
          "enableLive": vue.enableLive,
          "type": vue.cartType,
          "single": vue.betSingle,
          "multiple": vue.betMultiple,
          "integral": vue.betIntegral,
          "integralDivided": vue.betIntegralDivided,
          "system": vue.betSystem,
          "minAmount": vue.minAmount,
          "currency": vue.currency,
          "currencySymbol": vue.currencySymbol,
          "sm_multiplicity": vue.sm_multiplicity,
          "sm_bonus": vue.sm_bonus,
          "sm_odds_total": vue.sm_odds_total,
          "sm_winning": vue.sm_winning,
          "sm_bonus_total": vue.sm_bonus_total,
          "sm_profit": vue.sm_profit,
          "sm_winning_total": vue.sm_winning_total,
          "in_multiplicity": vue.in_multiplicity,
          "in_combinations": vue.in_combinations,
          "in_odds_total_min": vue.in_odds_total_min,
          "in_odds_total_max": vue.in_odds_total_max,
          "in_winning_min": vue.in_winning_min,
          "in_winning_max": vue.in_winning_max,
          "in_bonus_min": vue.in_bonus_min,
          "in_bonus_max": vue.in_bonus_max,
          "in_profit_min": vue.in_profit_min,
          "in_profit_max": vue.in_profit_max,
          "in_winning_total_min": vue.in_winning_total_min,
          "in_winning_total_max": vue.in_winning_total_max,
          "sys_combinations": vue.sys_combinations,
          "sys_amount_total": vue.sys_amount_total,
          "sys_bonus_min": vue.sys_bonus_min,
          "sys_winning_min": vue.sys_winning_min,
          "sys_winning_total_min": vue.sys_winning_total_min,
          "sys_bonus_max": vue.sys_bonus_max,
          "sys_winning_max": vue.sys_winning_max,
          "sys_winning_total_max": vue.sys_winning_total_max,
          "sys_winning_all_total": vue.sys_winning_all_total,
          "sleep_live": vue.sleep_live,
          "max_winning": vue.max_winning,
          "max_odds": vue.max_odds,
          "live_min_amount": vue.live_min_amount,
          "live_max_amount": vue.live_max_amount,
          "live_max_winning": vue.live_max_winning,
          "live_max_multiplicity": vue.live_max_multiplicity,
          "enableButtonsCart": vue.enableButtonsCart,
          "buttonsCart": vue.buttonsCart,
          "enableQuickBetOnlyForAgency": vue.enableQuickBetOnlyForAgency,
          "enableDirectPrintAgency": vue.enableDirectPrintAgency,
          "enableAutoOpenFavourite": vue.enableAutoOpenFavourite,
          "shortCode": vue.enableShortCode,
          "repetitionVincol": vue.repetitionVincol,
          "prematch_max_amount": vue.prematch_max_amount
        }
      }

      await this.updateRules(payload)
          .then(async (res) => {
            console.log('Tutto ok')


            this.snackbar.color = "success";
            this.snackbar.text = "Regola aggiornata con successo";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            await this.cacheRules()

          }).catch((err) => {

            this.snackbar.color = "error";
            this.snackbar.text = "C'è stato un errore nel salvataggio";
            this.snackbar.snackbar = true;
            this.snackbar.mode = 'multi-line';
            this.snackbar.timeout = 5000;

            console.log(err)
          });

      await this.fetchSkins()
      await this.setPreload(false)
    },

    async getRulesCart(platformId) {

      var vue = this

      var skin = await _.find(vue.getListSkins, {"platform_id": platformId});
      var cartRule = _.find(skin.rules, {"type": "cart", "scope": "cart", "source": "betconsole"})

      if (cartRule != undefined) {
        vue.default = false

        vue.enablePrematch = (cartRule.detail.enablePrematch != undefined) ? cartRule.detail.enablePrematch : true,
        vue.enableLive = (cartRule.detail.enableLive != undefined) ? cartRule.detail.enableLive : true,

        vue.cartType = cartRule.detail.type
        vue.betSingle = cartRule.detail.single
        vue.betMultiple = cartRule.detail.multiple
        vue.betIntegral = cartRule.detail.integral
        vue.betIntegralDivided = cartRule.detail.integralDivided
        vue.currency = cartRule.detail.currency
        vue.currencySymbol = cartRule.detail.currencySymbol
        vue.minAmount = cartRule.detail.minAmount

        vue.sm_multiplicity = cartRule.detail.sm_multiplicity
        vue.sm_bonus = cartRule.detail.sm_bonus
        vue.sm_odds_total = cartRule.detail.sm_odds_total
        vue.sm_winning = cartRule.detail.sm_winning
        vue.sm_bonus_total = cartRule.detail.sm_bonus_total
        vue.sm_profit = cartRule.detail.sm_profit
        vue.sm_winning_total = cartRule.detail.sm_winning_total

        vue.in_multiplicity = cartRule.detail.in_multiplicity
        vue.in_combinations = cartRule.detail.in_combinations
        vue.in_odds_total_min = cartRule.detail.in_odds_total_min
        vue.in_odds_total_max = cartRule.detail.in_odds_total_max
        vue.in_winning_min = cartRule.detail.in_winning_min
        vue.in_winning_max = cartRule.detail.in_winning_max
        vue.in_bonus_min = cartRule.detail.in_bonus_min
        vue.in_bonus_max = cartRule.detail.in_bonus_max
        vue.in_profit_min = cartRule.detail.in_profit_min
        vue.in_profit_max = cartRule.detail.in_profit_max
        vue.in_winning_total_min = cartRule.detail.in_winning_total_min
        vue.in_winning_total_max = cartRule.detail.in_winning_total_max

        vue.sys_combinations = cartRule.detail.sys_combinations
        vue.sys_amount_total = cartRule.detail.sys_amount_total
        vue.sys_bonus_min = cartRule.detail.sys_bonus_min
        vue.sys_winning_min = cartRule.detail.sys_winning_min
        vue.sys_winning_total_min = cartRule.detail.sys_winning_total_min
        vue.sys_bonus_max = cartRule.detail.sys_bonus_max
        vue.sys_winning_max = cartRule.detail.sys_winning_max
        vue.sys_winning_total_max = cartRule.detail.sys_winning_total_max
        vue.sys_winning_all_total = cartRule.detail.sys_winning_all_total

        vue.sleep_live = cartRule.detail.sleep_live
        vue.max_winning = cartRule.detail.max_winning
        vue.max_odds = cartRule.detail.max_odds

        vue.live_min_amount = cartRule.detail.live_min_amount
        vue.live_max_amount = cartRule.detail.live_max_amount
        vue.live_max_winning = cartRule.detail.live_max_winning
        vue.live_max_multiplicity = cartRule.detail.live_max_multiplicity

        vue.enableButtonsCart = (cartRule.detail.enableButtonsCart) ? cartRule.detail.enableButtonsCart : vue.enableButtonsCart
        vue.buttonsCart = (cartRule.detail.buttonsCart) ? cartRule.detail.buttonsCart : vue.buttonsCart
        vue.enableQuickBetOnlyForAgency = (cartRule.detail.enableQuickBetOnlyForAgency) ? cartRule.detail.enableQuickBetOnlyForAgency : vue.enableQuickBetOnlyForAgency
        vue.enableDirectPrintAgency = (cartRule.detail.enableDirectPrintAgency) ? cartRule.detail.enableDirectPrintAgency : vue.enableDirectPrintAgency
        vue.enableAutoOpenFavourite = (cartRule.detail.enableAutoOpenFavourite) ? cartRule.detail.enableAutoOpenFavourite : vue.enableAutoOpenFavourite
        vue.enableShortCode = (cartRule.detail.shortCode) ? cartRule.detail.shortCode : vue.enableShortCode
        vue.repetitionVincol = (cartRule.detail.repetitionVincol) ? cartRule.detail.repetitionVincol : vue.repetitionVincol
        vue.prematch_max_amount = cartRule.detail.prematch_max_amount != null ? cartRule.detail.prematch_max_amount : vue.prematch_max_amount

      }

    }


  },
}
</script>

<style scoped>

</style>
