<template>
  <div id="betsFilter">
    <v-container fluid class="pb-0">
      <v-row align="center">

        <v-col md="2">
          <v-text-field :class="filterCode ? ' text-capitalize active-filter' : 'text-capitalize'"
                        :label="$t('bets.code')" dense v-model="filterCode" outlined></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field class="text-capitalize" :label="$t('bets.betCode')" dense v-model="filterbetCode"
                        outlined></v-text-field>
        </v-col>

        <v-col md="1">
          <v-text-field class="text-capitalize" :label="$t('event')" dense v-model="filterEventId"
                        outlined></v-text-field>
        </v-col>

        <v-col md="1" v-if="marketName != null && marketName != ''">
          <v-text-field class="text-capitalize" :label="$t('markets')" dense v-model="marketName" disabled
                        outlined></v-text-field>
        </v-col>

        <v-col md="1" v-if="outcomeName != null && outcomeName != ''">
          <v-text-field class="text-capitalize" :label="$t('sign')" dense v-model="outcomeName" disabled
                        outlined></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" md="2" v-if="skin && skin.signUp">
          <v-autocomplete
                  v-model="filterLogin" :items="userList"
                  item-text="userName" item-value="userName" dense filled
                  @update:search-input="changedValue"
          ></v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" md="2" v-else>
          <v-text-field class="text-capitalize" :label="$t('user.user')" dense v-model="filterLogin"
                        outlined></v-text-field>
        </v-col>
        <!--        <v-col md="1">-->
        <!--          <v-text-field class="text-capitalize" :label="$t('user.id')" dense v-model="filterAncestors" outlined></v-text-field>-->
        <!--        </v-col>-->
        <v-col class="d-flex" cols="12" md="1" v-if="skin && skin.signUp">
          <v-select class="text-capitalize" :items="SubnetCriteria" item-text="text" item-value="value"
                    :label="$t('networkDetail')" dense outlined v-model="filterSubnetCriteria"></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="searchCriteria" item-text="text" item-value="value"
                    :label="$t('generic.searchCriteria')" dense outlined
                    v-model="filterSearchCriteria"></v-select>
        </v-col>

        <v-col md="1">
          <v-dialog ref="dialog" v-model="dialogFromTime" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense v-model="filterFromTime" :label="$t('generic.from')"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            outlined></v-text-field>
            </template>
            <v-date-picker v-model="filterFromTime" scrollable
                           @input="dialogFromTime = false"></v-date-picker>
          </v-dialog>
        </v-col>

        <v-col md="1">
          <v-dialog ref="dialog" v-model="dialogToTime" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense v-model="filterToTime" :label="$t('generic.to')"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            outlined></v-text-field>
            </template>
            <v-date-picker v-model="filterToTime" scrollable @input="dialogToTime = false"></v-date-picker>
          </v-dialog>
        </v-col>

        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfContexts" item-text="text" item-value="value"
                    :label="$t('generic.context')" dense outlined v-model="filterContext"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfBets" item-text="text" item-value="value"
                    :label="$t('bets.type')" dense outlined v-model="filterType"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfResults" item-text="text" item-value="value"
                    :label="$t('bets.result')" dense outlined v-model="filterResult"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfStatus" item-text="text" item-value="value"
                    :label="$t('bets.status')" dense outlined v-model="filterStatus"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfPayed" item-text="text" item-value="value"
                    :label="$t('bets.payed')" dense outlined v-model="filterPayed"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1" v-if="getPlatformId == 0">
          <v-select class="text-capitalize" :items="this.getSkinFilters" item-text="name"
                    item-value="platform_id" :label="$t('bets.platform')" dense outlined
                    v-model="filterPlatform"></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" md="1">
          <v-select class="text-capitalize" :items="typeOfSent" item-text="text" item-value="value"
                    :label="$t('bets.betSent')" dense outlined v-model="filterSent"></v-select>
        </v-col>
        <v-col cols="12" md="1" class="d-flex flex-column align-center">
          <v-checkbox
                  class="no-padding no-margin"
                  v-model="enableTimezone"
                  :label="$t('enableTimezone')"
                  @change="checkTimezone">
          </v-checkbox>
          <v-col class="text-center mt-1 timezone_name">
            <span v-if="enableTimezone">
              {{ (this.skinTimezone === '') ? $t('noTimezoneSkin') : this.skinTimezone }}
            </span>
            <span v-else style="visibility: hidden;">&nbsp;</span>
          </v-col>
        </v-col>
        <v-col md="1" class="d-flex">
          <v-checkbox
                  class="no-padding no-margin"
                  v-model="excludeVoidBets"
                  :label="$t('excludeVoidBets')">
          </v-checkbox>
        </v-col>

        <v-col class="d-flex" cols="12" md="1">
          <!--          <v-select class="text-capitalize" :items="numberOfEvents" item-text="text" item-value="value" :label="$t('numberEvents')" dense outlined v-model="filterNumberBet"></v-select>-->
          <v-text-field class="text-capitalize" :label="$t('minNumberEvents')" dense v-model="minNumberBets"
                        outlined></v-text-field>

        </v-col>

        <v-col class="d-flex" cols="12" md="1">
          <v-text-field class="text-capitalize" :label="$t('maxNumberEvents')" dense v-model="maxNumberBets"
                        outlined></v-text-field>
        </v-col>
        <v-col md="1" class="d-flex">
          <v-checkbox class="no-padding no-margin" v-model="enableReviewFilter"
                      :label="$t('bets.review')" @change="checkReview"></v-checkbox>
        </v-col>
        <v-col cols="12" md="1" class="d-flex" style="padding: 0px;padding-bottom: 13px;">
          <v-btn @click="showOtherSettings = !showOtherSettings"
                 color="primary"
                 x-small text>
            {{ showOtherSettings ? $t('generic.hideOtherSettings') : $t('generic.showOtherSettings') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showOtherSettings" class="other_settings">
        <v-col cols="12" md="2" class="d-flex">
          <v-autocomplete
                  v-model="sportSelected"
                  :items="sports"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  small-chips
                  :label="$t('generic.sport')"
                  deletable-chips>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" class="d-flex">
          <v-autocomplete v-if="sportSelected"
                          :items="getMarketsMini.markets"
                          v-model="selectedMarket"
                          item-text="name"
                          item-value="unique_id"
                          :items-per-page="30"
                          :search="search"
                          item-key="unique_id"
                          outlined
                          dense
                          :label="$t('generic.markets')"
                          small-chips>
          </v-autocomplete>

        </v-col>

        <v-col cols="12" md="2" class="d-flex intersecate_event_market_query" v-if="filterEventId && selectedMarket">
          <v-checkbox class="checkbox_ies" v-model="intersectEventMarket" :label="$t('intersecateEventMarketQuery')">
          </v-checkbox>
        </v-col>

      </v-row>
      <v-row>
        <v-col class="d-flex" cols="12" md="" style="margin-bottom: 10px; justify-content: flex-end;">
          <v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}
          </v-btn>
          <v-btn depressed color="secondary" @click="searchBets" @keyup.enter="searchBets" class="mr-5">{{
            $t('generic.search') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import moment from "moment-timezone";
  import _ from "lodash";

  export default {
    name: "betsFilter",
    data() {
      return {
        skinTimezone: '',
        enableTimezone: true,
        enableReviewFilter: false,
        reviewFilter: false,
        dialogFromTime: false,
        dialogToTime: false,
        filterType: "",
        filterResult: "",
        filterStatus: "",
        filterPlatform: "",
        filterPayed: "",
        filterCode: "",
        filterbetCode: "",
        filterEventId: "",
        filterLogin: "",
        filterAncestors: "",
        filterQbet: "",
        filterContext: "",
        filterSearchCriteria: "create",
        filterSubnetCriteria: "no",
        filterFromTime: moment().format('YYYY-MM-DD'),
        filterToTime: moment().format('YYYY-MM-DD'),
        filterSent: "",
        userList: [],
        selectedUserId: 0,
        skin: {
          signUp: false
        },
        signUp: false,
        typeOfBets: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.single'), value: "single"},
          {text: this.$t('bets.multiple'), value: "multiple"},
          {text: this.$t('bets.integral'), value: "integral"},
          {text: this.$t('bets.system'), value: "system"}
        ],
        searchCriteria: [
          {text: this.$t('bets.creationDate'), value: "create"},
          {text: this.$t('bets.definitionDate'), value: "definition"},
        ],
        SubnetCriteria: [
          {text: 'no', value: "no"},
          {text: 'yes', value: "yes"},
        ],
        typeOfResults: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.pending'), value: "pending"},
          {text: this.$t('bets.win'), value: "win"},
          {text: this.$t('bets.lose'), value: "lose"},
          {text: this.$t('bets.void'), value: "void"},
          {text: this.$t('bets.cancelled'), value: "cancelled"}
        ],
        typeOfContexts: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.prematch'), value: "prematch"},
          {text: this.$t('bets.live'), value: "live"},
          {text: this.$t('bets.mixed'), value: "mixed"},
          {text: this.$t('bets.antepost'), value: "antepost"},
        ],
        typeOfStatus: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('bets.accepted'), value: "accepted"},
          {text: this.$t('bets.review'), value: "review"},
          {text: this.$t('bets.finished'), value: "finished"},
        ],
        typeOfPayed: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('generic.yes'), value: "yes"},
          {text: this.$t('generic.no'), value: "no"},
        ],
        typeOfSent: [
          {text: this.$t('generic.selectOption'), value: ""},
          {text: this.$t('generic.yes'), value: true},
          {text: this.$t('generic.no'), value: false},
        ],
        excludeVoidBets: false,
        // numberOfEvents: [
        //   {text: this.$t('generic.selectOption'), value: 'all'},
        //   {text: this.$t('1'), value: 1},
        //   {text: this.$t('2'), value: 2},
        //   {text: this.$t('3'), value: 3},
        //   {text: this.$t('4'), value: 4},
        // ],
        /*filterNumberBet: 'all',*/
        minNumberBets: null,
        maxNumberBets: null,
        showOtherSettings: false,
        sports: null,
        sportSelected: null,
        selectedMarket: null,
        search: '',
        signEvent: null,
        marketName: null,
        outcomeName: null,
        intersectEventMarket: false
      }
    },
    props: {
      eventIdParam: {
        type: String,
        default: null
      },
      fromTimeParam: {
        type: String,
        default: null
      },
      toTimeParam: {
        type: String,
        default: null
      },
      signEventParam: {
        type: String,
        default: null
      },
      marketNameParam: {
        type: String,
        default: null
      },
      outcomeNameParam:{
        type: String,
        default: null
      }
    },
    computed: {
      ...mapGetters({
        'getBets': 'bet/getBets',
        'getOptions': 'bet/getOptions',
        'getSkinFilters': 'skins/getSkinFilters',
        'getPlatformId': 'rules/getPlatformId',
        'getList': 'skins/getList',
        'getTimezone': 'bet/getTimezone',
        'getMarketsMini': 'utils/getMarketsMini',
        'getLanguage': 'profile/getLanguage',
        'getItemsSport': 'sport/getItems',
      }),
    },
    async mounted() {
      await this.fetchSkins();
      await this.getPlatformsignUp(this.getPlatformId);
      if (this.getPlatformId === 0) {
        this.enableTimezone = false;
      }

      if (this.eventIdParam != null) {
        this.filterEventId = this.eventIdParam;

        if (this.fromTimeParam != null) {
          this.filterFromTime = this.fromTimeParam;
        }

        if (this.toTimeParam != null) {
          this.filterToTime = this.toTimeParam;
        }

        if(this.signEventParam != null){
          this.signEvent = this.signEventParam;
        }

        if(this.marketNameParam != null){
          this.marketName = this.marketNameParam;
        }

        if(this.outcomeNameParam != null){
          this.outcomeName = this.outcomeNameParam;
        }

        await this.searchBets();
      }
      this.fetchSports({language: this.getLanguage});
    },
    watch: {
      sportSelected: 'fetchMarkets',
      getItemsSport(newValue) {
        this.sports = newValue;
      },
      getSkinFilters(newValue, oldValue) {
        this.getPlatformsignUp(this.getPlatformId)
      },
      filterLogin(newValue, oldValue) {
        let found = false
        for (const el of this.userList) {
          if (el.userName === newValue) {
            this.selectedUserId = +el.userId;
            found = true
          }
        }

        if (found == false) {
          this.selectedUserId = 0;
        }
      },
      filterPlatform(newValue, oldValue) {
        console.log('newValue')
        console.log(newValue)

        console.log('oldValue')
        console.log(oldValue)

        if (newValue != null && newValue != '' && newValue > 0) {

          this.enableTimezone = true;
          this.checkTimezone();
        } else {
          this.enableTimezone = false;
          this.checkTimezone();
        }
      }
    },
    methods: {
      ...mapActions({
        'setPreload': 'utils/setPreload',
        'fetchBets': 'bet/fetchBets',
        'sendBookieDefined': 'bet/sendBookie',
        'setCriteria': 'bet/setCriteria',
        'setTimezone': 'bet/setTimezone',
        'getUsers': 'skins/getUsers',
        'getSignUpUsers': 'skins/getSignUpUsers',
        'getNewSignUpUsers': 'skins/getNewSignUpUsers',
        'fetchSports': 'sport/fetchSports',
        'fetchSkins': 'skins/fetchSkins',
        'getMarketsBySportList': 'utils/getMarketsBySportList',
      }),

      async fetchMarkets() {
        this.selectedMarket = null;
        if (this.sportSelected) {
          await this.setPreload(true);

          await this.getMarketsBySportList({
            sport: this.sportSelected,
            lang: this.getLanguage,
            enableSpecifiers: false,
          });

          await this.setPreload(false);
        }
      },
      getPlatformsignUp(platform_id) {
        this.skin = _.find(this.getSkinFilters, {platform_id: platform_id})
        // this.searchBets();
      },
      checkReview() {
        if (this.enableReviewFilter) {
          this.reviewFilter = true
        } else {
          this.reviewFilter = false
        }

      },
      changedValue: async function (value) {
        const managerId = 0;
        const platformId = this.getPlatformId;
        // this.selectedUserId = 0;
        this.userList = [];
        if (platformId === managerId) {
          return;
        }

        /* todo have to pass the userId not the login */

        if (this.skin.signUp) {
          //signup
          const requestObj = {
            skin_id: this.skin.settings.skin_id, //BOOKIE
            platformId: this.getPlatformId, //ROCKY
            userType: "",
            page: 1,
            status: "",
            userName: value,
            userId: "",
          }
          const response = await this.getNewSignUpUsers(requestObj);
          const usersBlocked = response.usersBlocked;
          if (usersBlocked != null && usersBlocked.length > 0) {

            this.userList = usersBlocked;
          }
        } else {
          //bookie
          const requestObj = {
            platformId: platformId,
            userType: [2, 3, 4]
          }
          const response = this.getUsers(requestObj);
          const usersBlocked = response.data.usersBlocked;
          if (usersBlocked != null && usersBlocked.length > 0) {
            this.userList = usersBlocked;
          }
        }
      },
      checkTimezone() {
        var platform = _.find(this.getList, {'platform_id': this.filterPlatform != null && this.filterPlatform != '' && this.filterPlatform > 0 ? this.filterPlatform : this.getPlatformId})
        if (this.enableTimezone) {
          if (platform.settings.timezone) {
            this.skinTimezone = platform.settings.timezone
            this.setTimezone(platform.settings.timezone)
          }
        } else {
          this.skinTimezone = ''
          this.setTimezone('')
        }
      },
      async searchBets() {

        if (this.getPlatformId > 0) {
          this.filterPlatform = this.getPlatformId
        }
        var platform = _.find(this.getList, {'platform_id': this.filterPlatform != null && this.filterPlatform != '' && this.filterPlatform > 0 ? this.filterPlatform : this.getPlatformId})
        console.log('platform use: ')
        console.log(platform)
        var startD;
        var endD;
        if (this.enableTimezone) {
          console.log('timezone enabled')
          if (platform.settings.timezone) {
            console.log('platform.settings.timezone')
            console.log(platform.settings.timezone)

            startD = moment.tz(this.filterFromTime, platform.settings.timezone).startOf('day').valueOf();
            console.log('startD');
            console.log(startD);

            // let endD = moment.tz(this.filterToTime, platform.settings.timezone).endOf('day').valueOf();
            // console.log('endD');
            // console.log(endD); //

            // let endD = moment.tz(this.filterToTime, platform.settings.timezone)
            //         .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            //         .valueOf();
            // console.log('endD');
            // console.log(endD);

            endD = moment.tz(this.filterToTime, platform.settings.timezone).endOf('day').subtract(1, 'seconds').valueOf();

            console.log('endD');
            console.log(endD);

          } else {
            startD = new Date(this.filterFromTime).getTime();
            endD = new Date(this.filterToTime).setUTCHours(23, 59, 59, 999);
          }
        } else {
          startD = new Date(this.filterFromTime).getTime();
          endD = new Date(this.filterToTime).setUTCHours(23, 59, 59, 999);
        }

        var criteria = {
          ...(this.filterPlatform !== '') && {platform_id: +this.filterPlatform},
          ...(this.filterType !== '') && {type: this.filterType},
          ...(this.filterSearchCriteria !== '') && {search_criteria: this.filterSearchCriteria},
          ...(this.filterResult !== '') && {result: this.filterResult},
          ...(this.filterStatus !== '') && {status: this.filterStatus},
          ...(this.filterPayed !== '') && {payed: this.filterPayed},
          ...(this.filterSent !== '') && {sent_to_platform: this.filterSent},
          ...(this.filterCode !== '') && {code: this.filterCode},
          ...(this.filterbetCode !== '') && {betCode: this.filterbetCode},
          ...(this.filterEventId !== '') && {id_event: this.filterEventId},
          ...(this.filterLogin !== '' && this.filterSubnetCriteria === 'no') && {login: this.filterLogin},
          ...(this.filterContext !== '') && {bet_context: this.filterContext},
          ...(this.reviewFilter !== false) && {inReview: this.reviewFilter},
          ...(this.filterFromTime !== '') && {fromTime: startD},
          ...(this.filterToTime !== '') && {toTime: endD},
          ...(this.excludeVoidBets != false) && {excludeVoidBets: this.excludeVoidBets},
          ...(this.minNumberBets != null && this.minNumberBets != '') && {minNumberBets: this.minNumberBets},
          ...(this.maxNumberBets != null && this.maxNumberBets != '') && {maxNumberBets: this.maxNumberBets},
          ...(this.selectedMarket != null && this.selectedMarket != '') && {marketId: this.selectedMarket},
          ...(this.signEvent != null && this.signEvent != '') && {signEvent: this.signEvent},
          ...(this.filterEventId !== '' && this.selectedMarket != null && this.selectedMarket != '' && this.intersectEventMarket != null && this.intersectEventMarket != '') && {intersectEventMarket: this.intersectEventMarket}
        }

        if (this.selectedUserId !== 0 && this.filterSubnetCriteria === 'yes') {
          criteria.ancestors = this.selectedUserId
        }


        this.getOptions.page = 1
        criteria.page = this.getOptions.page
        criteria.limit = this.getOptions.limit
        criteria.platform_id = this.filterPlatform

        await this.setPreload(true);
        await this.setCriteria(criteria)
        await this.fetchBets(criteria)
        await this.setPreload(false);
        await this.checkTimezone();


      },
      async sendBookie() {

        if (this.getPlatformId > 0) {
          this.filterPlatform = this.getPlatformId
        }
        var platform = _.find(this.getList, {'platform_id': this.getPlatformId})
        var startD;
        var endD;
        if (this.enableTimezone) {
          if (platform.settings.timezone) {
            startD = moment(this.filterFromTime + ' 00:00:00').tz(this.getTimezone).valueOf();
            endD = moment(this.filterToTime + ' 23:59:59').tz(this.getTimezone).valueOf();
          } else {
            startD = new Date(this.filterFromTime).getTime();
            endD = new Date(this.filterToTime).getTime() + 86399000;
          }
        } else {
          startD = new Date(this.filterFromTime).getTime();
          endD = new Date(this.filterToTime).getTime() + 86399000;
        }

        var criteria = {
          ...(this.filterPlatform !== '') && {platform_id: +this.filterPlatform},
          ...(this.filterType !== '') && {type: this.filterType},
          ...(this.filterResult !== '') && {result: this.filterResult},
          ...(this.filterStatus !== '') && {status: this.filterStatus},
          ...(this.filterPayed !== '') && {payed: this.filterPayed},
          ...(this.filterSent !== '') && {sent_to_platform: false},
          ...(this.filterCode !== '') && {code: this.filterCode},
          ...(this.filterbetCode !== '') && {betCode: this.filterbetCode},
          ...(this.filterEventId !== '') && {id_event: this.filterEventId},
          ...(this.filterLogin !== '' && this.filterSubnetCriteria === 'no') && {login: this.filterLogin},
          ...(this.filterContext !== '') && {bet_context: this.filterContext},
          ...(this.reviewFilter !== false) && {inReview: this.reviewFilter},
          ...(this.filterFromTime !== '') && {fromTime: startD},
          ...(this.filterToTime !== '') && {toTime: endD},
        }
        if (this.selectedUserId !== 0 && this.filterSubnetCriteria === 'yes') {
          criteria.ancestors = this.selectedUserId
        }


        this.getOptions.page = 1
        criteria.page = 1
        criteria.limit = 100
        criteria.platform_id = this.filterPlatform

        await this.setPreload(true);
        await this.setCriteria(criteria)
        await this.sendBookieDefined(criteria)
        await this.setPreload(false);


      },
      async resetFilter() {

        this.filterPlatform = ''
        this.filterType = ''
        this.filterResult = ''
        this.filterStatus = ''
        this.filterPayed = ''
        this.filterCode = ''
        this.filterbetCode = ''
        this.filterContext = ''
        this.filterFromTime = moment().format('YYYY-MM-DD')
        this.filterToTime = moment().format('YYYY-MM-DD')
        this.filterSent = ''
        this.filterLogin = ''
        this.reviewFilter = false
        this.filterEventId = '';
        this.selectedMarket = null;
        this.signEvent = null;
        this.marketName = null;
        this.outcomeName = null;
        // await this.searchBets()

        this.sportSelected = null;
        this.selectedMarket = null;
        this.intersectEventMarket = false;
        this.$store.commit('bet/setBets', [])
        this.$store.commit('bet/setBetsTotals', [])

      },
      async handleKeyDown(e) {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          await this.searchBets()
        }
      },
    },
    created() {
      window.addEventListener('keydown', this.handleKeyDown);
    },
    destroyed() {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }
</script>

<style scoped>
  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.text-capitalize.active-filter:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    border-color: #4ecd00 !important;
  }

  .timezone_name {
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
  }

</style>
